/**
 * A service for submitting feedback.
 * @ngInject
 */
function FeedbackService($http, UserPrefsService) {
  return {
    /**
     * Get feedback that has been submitted for the ID and family provided.
     * This needs to be retrieved from the service instead of the directive because
     * multiple <feedback-icons> directive instances can be responsible for the same feedback
     * (for example, list and grid view for the same family and ID combination).
     *
     * @param {String} id Identifier of the thing we're collecting feedback on
     * @param {String} familySlug Optional TypographicFamily slug
     * @return {String}
     */
    getSubmittedFeedback: function(id, familySlug) {
      this._setDefaultSubmittedFeedback(id, familySlug);
      if (familySlug) {
        return this._submittedFeedback[id]['familyFeedback'][familySlug];
      } else {
        return this._submittedFeedback[id]['feedback'];
      }
    },

    /**
     * Create a record for this family as users favorite.
     *
     * @param {String} category String feedback category
     * @param {String} subcategory String feedback subcategory
     * @param {String} id Identifier of the thing we're collecting feedback on
     * @param {String} feedbackType Type of feedback (either 'positive' or 'negative')
     * @param {String} familySlug Optional TypographicFamily slug
     * @return {Promise}
     */
    post: function(category, subcategory, id, feedbackType, familySlug, variationOne, variationTwo) {
      var self = this;
      return UserPrefsService.getUserPrefs().then(function(prefs) {
        if (self.getSubmittedFeedback(id, familySlug) === feedbackType) {
          return;
        }

        var params = self._getFeedbackParams(category, subcategory, id, feedbackType, familySlug, prefs, variationOne, variationTwo);
        return $http.post('/feedbacks.json', params).then(function() {
          self.setSubmittedFeedback(id, feedbackType, familySlug);
        });
      });
    },

    /**
     * Save changes to the filters model so that it can be sent with feedback for analytics purposes
     * @param {Object} filtersModel Filters model
     */
    setFiltersModel: function(filtersModel) {
      this.filtersModel = filtersModel;
    },

    /**
     * Record feedback that has been submitted for the ID and family provided.
     * See getSubmittedFeedback for details on why this is needed.
     *
     * @param {String} id Identifier of the thing we're collecting feedback on
     * @param {String} feedbackType Type of feedback (either 'positive' or 'negative')
     * @param {String} familySlug Optional TypographicFamily slug
     */
    setSubmittedFeedback: function(id, feedbackType, familySlug) {
      this._setDefaultSubmittedFeedback(id, familySlug);
      if (familySlug) {
        this._submittedFeedback[id]['familyFeedback'][familySlug] = feedbackType;
      } else {
        this._submittedFeedback[id]['feedback'] = feedbackType;
      }
    },

    /**
     * Private: Get params sent in a request to POST /feedbacks
     *
     * @param {String} category String feedback category
     * @param {String} subcategory String feedback subcategory
     * @param {String} id Identifier of the thing we're collecting feedback on
     * @param {String} feedbackType Type of feedback (either 'positive' or 'negative')
     * @param {String} familySlug Optional TypographicFamily slug
     * @param {Object} prefs User prefs
     * @return {Object}
     */
    _getFeedbackParams: function(category, subcategory, id, feedbackType, familySlug, prefs, variationOne, variationTwo) {
      var params = {
        category: category,
        feedback_type: feedbackType,
        id: id,
        view_type: prefs.selectedViewType
      };
      if (subcategory) {
        params['subcategory'] = subcategory;
      }
      if (this.filtersModel) {
        params.filters = this.filtersModel.getSelectedFilters().join(',');
        params.languages = this.filtersModel.getSelectedLanguages().join(',');
        params.locale = this.filtersModel.getLocale();
      }
      if (familySlug) {
        params['family_slug'] = familySlug;
      }
      if (variationOne) {
        params['variation_one'] = variationOne;
      }
      if (variationTwo) {
        params['variation_two'] = variationTwo;
      }
      return params;
    },

    /**
     * Private: Initialize empty objects in _submittedFeedback to avoid undefined values.
     *
     * @param {String} id Identifier of the thing we're collecting feedback on
     * @param {String} familySlug Optional TypographicFamily slug
     */
    _setDefaultSubmittedFeedback: function(id, familySlug) {
      this._submittedFeedback = this._submittedFeedback || {};
      this._submittedFeedback[id] = this._submittedFeedback[id] || {};
      if (familySlug) {
        this._submittedFeedback[id]['familyFeedback'] = this._submittedFeedback[id]['familyFeedback'] || {};
      }
    }
  };
}

module.exports = FeedbackService;
