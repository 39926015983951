/**
 * A service used to keep track of font counts.
 * @ngInject
 */
function FontCountService() {
  var allActivatedFontsCount;
  var allPreviouslyActivatedFontsCount;
  var filteredActivatedFontsCount;
  var thirtyDayActivatedFontsCount;
  var allFamiliesCount;

  return {
    getAllActivatedFontsCount: getAllActivatedFontsCount,
    getAllPreviouslyActivatedFontsCount: getAllPreviouslyActivatedFontsCount,
    getFilteredActivatedFontsCount: getFilteredActivatedFontsCount,
    getThirtyDayActivatedFontsCount: getThirtyDayActivatedFontsCount,
    getAllFamiliesCount: getAllFamiliesCount,
    isAllActivatedFontsCountSet: isAllActivatedFontsCountSet,
    isAllPreviouslyActivatedFontsCountSet: isAllPreviouslyActivatedFontsCountSet,
    isFilteredActivatedFontsCountSet: isFilteredActivatedFontsCountSet,
    isThirtyDayActivatedFontsCountSet: isThirtyDayActivatedFontsCountSet,
    isAllFamiliesCountSet: isAllFamiliesCountSet,
    setAllActivatedFontsCount: setAllActivatedFontsCount,
    setAllPreviouslyActivatedFontsCount: setAllPreviouslyActivatedFontsCount,
    setFilteredActivatedFontsCount: setFilteredActivatedFontsCount,
    setThirtyDayActivatedFontsCount: setThirtyDayActivatedFontsCount,
    setAllFamiliesCount: setAllFamiliesCount,
    updateActivatedFontsCount: updateActivatedFontsCount,
    updatePreviouslyActivatedFontsCount: updatePreviouslyActivatedFontsCount,
    updateFamiliesCount: updateFamiliesCount
  };

  /**
   * Get all activated fonts count
   *
   * @returns {Number}
   */
  function getAllActivatedFontsCount() {
    return allActivatedFontsCount;
  }

  /**
   * Get all previously activated fonts count
   *
   * @returns {Number}
   */
  function getAllPreviouslyActivatedFontsCount() {
    return allPreviouslyActivatedFontsCount;
  }

  /**
   * Get filtered activated fonts count
   *
   * @returns {Number}
   */
  function getFilteredActivatedFontsCount() {
    return filteredActivatedFontsCount;
  }

  /**
   * Get the count of filtered fonts activated in the past 30 days
   *
   * @returns {Number}
   */
  function getThirtyDayActivatedFontsCount() {
    return thirtyDayActivatedFontsCount;
  }

  /**
   * Get the count of all families
   *
   * @returns {Number}
   */
  function getAllFamiliesCount() {
    return allFamiliesCount;
  }

  /**
   * Checks if the count of all activated fonts is set
   *
   * @return {Boolean}
   */
  function isAllActivatedFontsCountSet() {
    return !(typeof allActivatedFontsCount === 'undefined');
  }

  /**
   * Checks if the count of all previously activated fonts is set
   *
   * @return {Boolean}
   */
  function isAllPreviouslyActivatedFontsCountSet() {
    return !(typeof allPreviouslyActivatedFontsCount === 'undefined');
  }

  /**
   * Checks if filtered activated fonts count is set
   *
   * @return {Boolean}
   */
  function isFilteredActivatedFontsCountSet() {
    return !(typeof filteredActivatedFontsCount === 'undefined');
  }

  /**
   * Checks if filtered 30-day activated fonts count is set
   *
   * @return {Boolean}
   */
  function isThirtyDayActivatedFontsCountSet() {
    return !(typeof thirtyDayActivatedFontsCount === 'undefined');
  }

  /**
   * Checks if the count of all families is set
   *
   * @return {Boolean}
   */
  function isAllFamiliesCountSet() {
    return !(typeof allFamiliesCount === 'undefined');
  }

  /**
   * Sets the count of all activated fonts
   *
   * @param count
   */
  function setAllActivatedFontsCount(count) {
    allActivatedFontsCount = count;
  }

  /**
   * Sets the count of all previously activated fonts
   *
   * @param count
   */
  function setAllPreviouslyActivatedFontsCount(count) {
    allPreviouslyActivatedFontsCount = count;
  }

  /**
   * Sets the count of filtered activated fonts
   *
   * @param count
   */
  function setFilteredActivatedFontsCount(count) {
    filteredActivatedFontsCount = count;
  }

  /**
   * Set the count of filtered fonts activated in the past 30 days
   *
   * @returns {Number}
   */
  function setThirtyDayActivatedFontsCount(count) {
    thirtyDayActivatedFontsCount = count;
  }

  /**
   * Sets the count of all families
   *
   * @returns {Number}
   */
  function setAllFamiliesCount(count) {
    allFamiliesCount = count;
  }

  /**
   * Updates the activated fonts count.
   *
   * This function assumes that the count changed now, so the count for fonts
   * activated in the past 30 days should also be updated.
   *
   * @param count
   */
  function updateActivatedFontsCount(count) {
    if (isAllActivatedFontsCountSet()) {
      allActivatedFontsCount += count;
    }

    if (isFilteredActivatedFontsCountSet()) {
      filteredActivatedFontsCount += count;
    }

    if (isThirtyDayActivatedFontsCountSet()) {
      thirtyDayActivatedFontsCount += count;
    }
  }

  /**
   * Updates the previously activated fonts count.
   *
   * @param count
   */
  function updatePreviouslyActivatedFontsCount(count) {
    if (isAllPreviouslyActivatedFontsCountSet()) {
      allPreviouslyActivatedFontsCount += count;
    }
  }


  /**
   * Updates the activated families count.
   *
   * @param count
   */
  function updateFamiliesCount(count) {
    if (isAllFamiliesCountSet()) {
      allFamiliesCount += count;
    }
  }
}

module.exports = FontCountService;
