var angular = require('angular');
const handlePageClick = require('../../util/new_relic.js').handlePageClick;

/**
 * A service for tracking clicks and inputs for Newrelic.
 *
 * @ngInject
 */
function NewrelicClickTrackerService($document) {
  let page;
  return {
    init: init
  };

  function init(options) {
    polyfillElementMatches();
    page = options.page;
    angular.element($document[0].body).on('click', _handlePageClick);
  }

  function _handlePageClick(event) {
    handlePageClick(event, page);
  }

  /**
  * Polyfill the Element.matches method for older browsers that don't natively
  * support it.
  */
  function polyfillElementMatches() {
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
    }
  }
}

module.exports = NewrelicClickTrackerService;
