/**
 * A service for reading Floodgate flags from IMSLib.
 *
 * See https://git.corp.adobe.com/pages/IMS/imslib.js/tutorial-Floodgate.html for more documentation.
 *
 * Usage:
 *
 * FloodgateService.load().then(function() {
 *   FloodgateService.isReleaseEnabled('MY_RELEASE_NAME_HERE');
 * });
 *
 * @ngInject
 */
function FloodgateService($q, $window, DataService, ImsEventsService) {
  return {
    isReleaseEnabled: isReleaseEnabled,
    load: load
  };

  /**
   * Is the release provided enabled?
   *
   * @param {String} release Name of the release in Floodgate
   *
   * @returns {Boolean}
   */
  function isReleaseEnabled(release) {
    if (this.releaseFlagBitIndices == null || this.releaseFlagBitString == null) {
      throw new Error('FloodgateService has not loaded yet. Make sure to call load() first.');
    }

    // An empty bit string indicates an error response. Treat the flag as disabled in that case.
    if (this.releaseFlagBitString.length === 0) {
      return false;
    }

    var fgValue = null;

    if (this.releaseFlagBitIndices[release]) {
      fgValue = this.releaseFlagBitString[this.releaseFlagBitIndices[release]] === '1';
    }

    return fgValue;
  }

  /**
   * Loads a bit string from IMS containing release flags and our mapping of a feature to an index in the string.
   *
   * @returns {Promise}
   */
  function load() {
    var self = this;

    return Promise.allSettled([
      DataService.get('/neue/release_flag_bit_indices').then(function(bitIndices) {
        self.releaseFlagBitIndices = bitIndices;
      }),

      new Promise(function(resolve) {
        ImsEventsService.addOnReadyHandler(function () {
          // Updating to IMS 2 implementation
          // https://adobetype.slack.com/archives/C78VB0TMG/p1644318081712579
          $window.adobeIMS.getReleaseFlags(true).then((decodedFGValue) => {
            self.releaseFlagBitString = decodedFGValue;
            resolve();
          }).catch(() => {
            self.releaseFlagBitString = '';
            resolve();
          });
        });
      })
    ]);
  }
}

module.exports = FloodgateService;
